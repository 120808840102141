<template>
  <div class="wrap">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/QCGF' }">质控指标</el-breadcrumb-item>
      <el-breadcrumb-item>共识指南文献</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="table">
      <el-table :data="list" style="width: 100%">
        <el-table-column label="文件名称">
          <template slot-scope="scope">
            <el-link :href="scope.row.Link" target="_blank">{{ scope.row.Name }}</el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { GetQCFiles } from "@/api/index.js";

export default {
  name: "ZKWJ",
  data() {
    return {
      list: [],
    };
  },
  methods: {
    async GetQCFilesAsync() {
      const r = await GetQCFiles();
      if (r.code == 1) {
        this.list = r.data;
      } else {
        this.$message.error(r.error.Message);
      }
    },
    handleClick(row) {
      const link = row.link;
      console.log(link);
    },
  },
  created() {
    this.GetQCFilesAsync();
  },
};
</script>

<style scoped>
.table {
  margin-top: 20px;
}
</style>